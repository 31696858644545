<template>
  <v-container id="admins-page" fluid tag="section">
    <v-row class="mx-0" align="center" justify="center">
      <v-col class="d-flex align-center justify-center" cols="12">
        <base-material-card color="primary" class="px-5 py-3 w-100">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Administrator Settings</div>
            <div class="subtitle-1 font-weight-light">Setup your Administrator Profile here</div>
          </template>
          <v-card-text class="w-100 px-3 py-5" ref="form">
            <v-row class="w-100 mx-0" align="center" justify="center">
              <v-col cols="12">
                <v-form ref="form" @submit.prevent="submitFn">
                  <v-row align="center" justify="center" justify-sm="start">
                    <!-- <v-col cols="12" sm="6" md="4" class="py-0">
                      <v-text-field ref="emailAddress" v-model="email" label="Email" outlined></v-text-field>
                    </v-col>-->
                    <v-col cols="12" sm="6" md="4" class="py-0">
                      <v-text-field
                        type="password"
                        label="Current Password"
                        ref="Password"
                        v-model="currentPwd"
                        outlined
                        value="random"
                        :error-messages="errorCurrentPwd"
                      ></v-text-field>
                    </v-col>

                    <!-- :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    @click:append="show = !show"-->

                    <!-- :loading="isApiLoad" -->
                    <v-col cols="12" sm="6" md="4" class="py-0">
                      <v-text-field
                        type="password"
                        ref="Password"
                        v-model="newPwd"
                        label=" New Password"
                        outlined
                        :error-messages="errorNewPwd"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="py-0">
                      <v-text-field
                        type="password"
                        ref="Password"
                        v-model="confirmPwd"
                        label="Confirm New Password"
                        outlined
                        :error-messages="errorConfirmPwd"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <v-row align="center" justify="center">
                <v-col cols="12" class="py-0">
                  <v-text-field
                    type="password"
                    ref="key"
                    v-model="secKey"
                    label="Secret Key"
                    outlined
                    :error-messages="errorSecKey"
                  ></v-text-field>
                </v-col>
                  </v-row>-->
                  <v-row align="center" justify="center" class="mx-auto mb-3 mt-n1 error-row">
                    <span class="caption text-center error--text">{{mainError}}</span>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-col
                      cols="6"
                      class="d-flex flex-column align-center justify-space-between py-0"
                    >
                      <v-btn
                        type="submit"
                        class="px-12 mb-3 mx-auto"
                        color="secondary"
                        tile
                        depressed
                        bottom
                        :loading="isApiLoad"
                      >Submit</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/* eslint-disable */

import adminsApicalls from "../../../services/admin.sevice";

export default {
  name: "Settings",
  data() {
    return {
      currentPwd: null,
      newPwd: null,
      confirmPwd: null,
      mainError: null,
      errorCurrentPwd: null,
      errorNewPwd: null,
      errorConfirmPwd: null,
      isApiLoad: false,
      staticErrorMessages: {
        currentPwd: {
          isEmpty: "Current passsword should not be empty"
        },
        newPwd: {
          isEmpty: "New passsword should not be empty"
        },
        confirmPwd: {
          isEmpty: "Confrm passsword feild cannot be empty",
          notMatch:
            "It seems that confirm password and new password  are not matching"
        }
      }
    };
  },
  watch: {
    currentPwd() {
      this.errorCurrentPwd = null;
    },
    newPwd() {
      this.errorNewPwd = null;
    },
    confirmPwd() {
      this.errorConfirmPwd = null;
    }
  },
  methods: {
    submitFn() {
      if (
        this.newPwd &&
        this.currentPwd &&
        this.confirmPwd &&
        this.newPwd === this.confirmPwd
      ) {
        this.isApiLoad = true;
        let payload = {
          id: JSON.parse(localStorage.getItem("admin")).admin._id,
          CurrentPassword: this.currentPwd,
          NewPassword: this.newPwd
        };
        console.log(payload);

        adminsApicalls.setChangePWd(payload).then(res => {
          // console.log("resMA", res.data);

          if (res.data.message == "success") {
            console.log(res.data.content);
            let payload2 = {
              snackBarText: "password resetted successfully",
              snackBarColor: "success"
            };
            this.$store.dispatch("notify/setSnackbarMessage", payload2);
            this.$router.push("admins");
          }
          else {
            this.isApiLoad = false;
            this.mainError = res.data.details.CurrentPassword;
            this.newPwd = null;
            this.currentPwd = null;
            this.confirmPwd = null;
            setTimeout(() => {
              this.mainError = null;
            }, 2000);
            // let payload2 = {
            //   snackBarText: "old password is incorrect please try again",
            //   snackBarColor: "error"
            // };
            // this.$store.dispatch("notify/setSnackbarMessage", payload2);
          }

          
        });
      } else {
        if (!this.currentPwd) {
          this.errorCurrentPwd = this.staticErrorMessages.currentPwd.isEmpty;
        }
        if (!this.newPwd) {
          this.errorNewPwd = this.staticErrorMessages.newPwd.isEmpty;
        }
        if (!this.confirmPwd) {
          this.errorConfirmPwd = this.staticErrorMessages.confirmPwd.isEmpty;
        }
        if (this.newPwd != this.confirmPwd) {
          this.errorConfirmPwd = this.staticErrorMessages.confirmPwd.notMatch;
        }
      }
    }
  }
};
</script>
<style>
#register {
  background-image: linear-gradient(
      to left,
      rgba(53, 167, 255, 0.3),
      rgba(53, 167, 255, 0.3)
    ),
    url("https://source.unsplash.com/w1g2o4J_4Dg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.register-form-card {
  position: relative;
  width: 600px;
}
.register-form-row {
  /* position: absolute; */
  top: 0;
  min-width: 100vw;
  /* the 64px is to offset the top app bar */
  height: calc(100vh - 64px);
  min-height: 300px;
}
.register-image-credits {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: white;
}
.register-image-credits a {
  color: white;
}
.register-image-credits a:hover {
  color: gray;
}
/* Medium devices (large tablet to laptop, less than 960px) */
@media (max-width: 960px) {
  .register-form-card {
    width: 80vw;
    min-width: 200px;
  }
  /* the 56px is to offset the top app bar on smaller devices */
  .register-cover-image {
    height: calc(100vh - 56px);
  }
  /* the 56px is to offset the top app bar on smaller devices */
  .register-form-row {
    height: calc(100vh - 56px);
  }
}
</style>